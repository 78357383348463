import React from 'react';
import { Helmet } from 'react-helmet';
import { PageProps, Link } from 'gatsby';

import Layout from '../components/Layout/Layout';
import Section from '../components/Section/Section';
import Button from '../components/Button/Button';

const Legal: React.FC<PageProps> = () => (
  <Layout>
    <Helmet>
      <title>XAPI - IEEE SA OPEN</title>
    </Helmet>
    <Section type={'light'}>
      <p>IEEE Policies and Resources:</p>
      <ul>
        <li>
          <a href={'https://standards.ieee.org/ipr/index.html'}>
            Standards Copyright IPR
          </a>
        </li>
        <li>
          <a href={'https://www.ieee.org/publications/rights/index.html'}>
            IEEE Intellectual Property Rights
          </a>
        </li>
        <li>
          <a
            href={'http://www.ieee.org/web/aboutus/whatis/policies/p9-26.html'}
          >
            IEEE Nondiscrimination Policy
          </a>
        </li>
        <li>
          <a
            href={
              'https://opensource.ieee.org/community/cla/terms-of-use/-/blob/master/terms-of-use.md'
            }
          >
            IEEE SA Open Source Platform Terms of Service
          </a>
        </li>
      </ul>
    </Section>
    <Section type={'dark'}>
      <h1>Code of Conduct</h1>
      <p>
        By using IEEE SA OPEN you agree to the{' '}
        <a
          href={
            'https://www.ieee.org/content/dam/ieee-org/ieee/web/org/about/ieee_code_of_conduct.pdf'
          }
        >
          Code of Conduct
        </a>
        .
      </p>
      <h4>Ethics Reporting Line</h4>
      <p>
        Reports of violations, or concerns regarding potential violations, of
        IEEE Policies or the IEEE Code of Conduct can be filed anonymously
        through IEEE Ethics Reporting Line at +1 888 359 6323{' '}
        <a
          href={
            'https://secure.ethicspoint.com/domain/en/report_custom.asp?clientid=20410'
          }
        >
          or by submitting an online report.
        </a>{' '}
        To learn more, click here to access{' '}
        <a
          href={
            'https://www.ieee.org/content/dam/ieee-org/ieee/web/org/about/corporate/08312020-Ethics-Line-FAQ-v1.pdf'
          }
        >
          IEEE Ethics Reporting Line FAQ
        </a>
        .
      </p>
    </Section>
    <Section type={'light'}>
      <h1>Terms of Use</h1>
      <p>
        By participating in IEEE SA OPEN you agree to the{' '}
        <a
          href={
            'https://opensource.ieee.org/community/cla/terms-of-use/-/blob/master/terms-of-use.md'
          }
        >
          IEEE SA OPEN Terms of Use.
        </a>
      </p>
    </Section>
    <Section type={'dark'}>
      <h1>Privacy Policy</h1>
      <p>Use of this platform is contingent upon your agreement to:</p>
      <ul>
        <li>
          <a href={'https://www.ieee.org/security-privacy.html'}>
            IEEE's Privacy Policy
          </a>
        </li>
        <li>
          <a href={'https://www.ieee.org/about/corporate/governance/p7-8.html'}>
            IEEE Code of Ethics
          </a>
        </li>
        <li>
          <a
            href={
              'https://www.ieee.org/content/dam/ieee-org/ieee/web/org/about/ieee_code_of_conduct.pdf'
            }
          >
            IEEE Code of Conduct
          </a>
        </li>
        <li>
          <a href={'https://www.ieee.org/site-terms-conditions.html'}>
            IEEE Terms and Condition
          </a>
        </li>
        <li>
          <a
            href={'https://www.ieee.org/about/corporate/governance/p9-26.html'}
          >
            IEEE Nondiscrimination Policy
          </a>
        </li>
      </ul>
    </Section>
    <Section type={'light'}>
      <h1>Contact Us</h1>
      <div className={'center'}>
        <p>
          Got a question about IEEE’s Open Source Community? We want to hear
          from you.
        </p>
        <Button link={'mailto:opensource@ieee.org'}>Contact Us</Button>
      </div>
      <p>
        Whether your question is about IEEE SA OPEN, an IPR issue, export
        controls, using the IEEE SA OPEN platform, creating your own partnership
        or anything else, our team is standing by.
      </p>
      <ul>
        <li>
          <a href={'https://opensource.ieee.org/users/sign_in'}>Sign up</a>
        </li>
        <li>
          <a href="mailto:opensourceopps@ieee.org">Partnerships</a>
        </li>
        <li>
          <a href={'https://opensource.ieee.org/welcome'}>Support</a>
        </li>
        <li>
          <a
            href={'https://opensource.ieee.org/community/cla/faq/-/wikis/home'}
          >
            IEEE SA OPEN FAQ
          </a>
        </li>
        <li>
          <a href={'https://opensource-connect.ieee.org/community/'}>Chat</a>
        </li>
      </ul>
    </Section>
  </Layout>
);

export default Legal;
