import React from 'react';
import styles from './Button.module.css';
import PropTypes from 'prop-types';

const Button = ({ children, link }) => {
  return (
    <a href={link}>
      <span className={styles.cta}>{children}</span>
    </a>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string,
};

export default Button;
